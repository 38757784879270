<template>
  <div id="Christmas">
    <div class="main-content">
      <h1>Merry Christmas!</h1>
      <p class="receiver">{{person}}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Christmas',
  components: {
  },
  data(){
    return{
      person: this.$route.params.person
    }
  },
  methods:{
  },
}
</script>
<style scoped>
#Christmas{
  width: 100%;
  height: 100vh;
  background: #a01008;
  /* min-height: 100vh; */
  display:flex;
  justify-content: center;
  align-items: center;
}
.main-content{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;

}
.main-content h1{
  font-size: 3.5rem;
}
.receiver{
  font-size: 2rem;
}
</style>
